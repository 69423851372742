
































































































































































































import { defineComponent, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api'
import { throttle } from '@shared/utils/functions/optimize.js'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { SuperMemberService } from '@/views/activity/super-member.service'
import SuperSchoolExplain from './_components/super-school-explain.vue'

export default defineComponent({
  components: {
    SuperSchoolExplain
  },
  setup (_, context) {
    const injector = useReflectiveInjector([SuperMemberService])
    const service = injector.get(SuperMemberService)
    const bigCardList = service.bigCardList
    const superInterestList = service.superInterestList
    const interestCards = service.interestCards
    const nfrom = context.root.$route.query?.nfrom || 1 // 来源

    const viewModel = reactive({
      positionLeft: 0,
      timer: null,
      scrollTop: 0,
      /** 所有权益切换开关 */
      interestFlag: false,
      /** 活动说明显示 */
      sceVisible: false,
      /** 活动时间 */
      activityTime: '',
      /** 常见问题 */
      problemList: [],
      recevicePeopleList: []
    })
    const receiveRef = ref(null)
    const scrollTopRef = ref(null)
    const akbRef = ref(null)

    onMounted(() => {
      init()
      viewModel.timer = requestAnimationFrame(() => {
        startAnimation()
      })
      window.addEventListener('scroll', () => {
        // eslint-disable-next-line
        let thr = throttle(() => {
          viewModel.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        }, 200)
        thr()
      })

      service.report({ nshow: 1, nfrom })
    })

    onUnmounted(() => {
      cancelAnimationFrame(viewModel.timer)
    })

    function init () {
      const params = {
        name: 'campus-trip'
      }
      service.requestActivityPageConfig(params).then(res => {
        viewModel.activityTime = res.detail?.time_interval
        viewModel.recevicePeopleList = res.detail?.winner_list
        handleCommonProblemList(res)
      })
    }

    /**
     * @description 处理常见问题列表
     */
    function handleCommonProblemList (obj) {
      const arr = []
      // eslint-disable-next-line no-unused-expressions
      obj.detail?.faq_question.forEach((e, i) => {
        arr.push({
          title: e,
          desc: obj.detail?.faq_answer[i]
        })
      })
      viewModel.problemList = arr
    }

    /**
     * @description 所有权益对比
     */
    function allInterestContrast () {
      service.report({ nfrom, click: viewModel.interestFlag ? 4 : 3 })
      viewModel.interestFlag = !viewModel.interestFlag
    }

    /**
     * @description 活动说明
     */
    function activityExplain () {
      viewModel.sceVisible = true
      service.report({ nfrom, click: 1 })
    }

    /**
     * @description 开始滚动动画
     */
    function startAnimation () {
      const width = receiveRef.value.scrollWidth - 784
      const nowLeft = viewModel.positionLeft

      if (Math.abs(nowLeft) >= width) {
        viewModel.positionLeft = 0
      } else {
        viewModel.positionLeft = nowLeft - 1
      }
      viewModel.timer = requestAnimationFrame(startAnimation)
    }

    /**
     * @description 返回顶部
     */
    function backTop () {
      let timer = null
      const run = () => {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        document.documentElement.scrollTop = document.body.scrollTop = top - 200

        if (top <= 0) {
          cancelAnimationFrame(timer)
          return
        }
        timer = requestAnimationFrame(run)
      }

      run()
      service.report({ nfrom, click: 5 })
    }

    /**
     * @description 免费领取超级会员
     */
    function freeReceiveSuperMember () {
      const position = akbRef.value.getBoundingClientRect().top - 80

      let timer = null
      const run = () => {
        const top = document.documentElement.scrollTop || document.body.scrollTop
        if (top >= position) {
          cancelAnimationFrame(timer)
          return
        }
        document.documentElement.scrollTop = document.body.scrollTop = top + 80
        timer = requestAnimationFrame(run)
      }

      run()
      service.report({ nfrom, click: 2 })
    }

    return {
      viewModel,
      akbRef,
      receiveRef,
      scrollTopRef,
      bigCardList,
      interestCards,
      superInterestList,
      backTop,
      activityExplain,
      allInterestContrast,
      freeReceiveSuperMember,
    }
  }
})

import { Injectable } from '@zhfe/vue2-di-plugin'
import { Http } from '@zhfe/common-library'
import { ReportService } from '@/service/report.service'

interface ActivityParams {
  /** 活动名称 */
  name: string
}

@Injectable()
export class SuperMemberService {
  /** 俩小人踩卡片 */
  bigCardList = [
    {
      imgClass: 'c_1',
      title: '轻巧无广告',
      desc: '专注学习、纯净上网'
    },
    {
      imgClass: 'c_2',
      title: '自动拦截弹窗',
      desc: '告别打扰、提速电脑'
    },
    {
      imgClass: 'c_3',
      title: 'PDF转换',
      desc: '阅读编辑转换强'
    },
    {
      imgClass: 'c_4',
      title: '快速看图',
      desc: '大图秒开、美图便捷'
    },
    {
      imgClass: 'c_5',
      title: '模板无忧',
      desc: '实用PPT、简历模板'
    },
  ]

  /** 超级会员权益卡片 */
  superInterestList = [
    {
      imgClass: 'i_1',
      title: '纯净无广告',
      desc: '所有产品无广告推广'
    },
    {
      imgClass: 'i_2',
      title: '特权扩充至48项',
      desc: '全面覆盖6大产品权益'
    },
    {
      imgClass: 'i_3',
      title: '全网更低价',
      desc: '超值价格 仅0.4元/日'
    },
    {
      imgClass: 'i_4',
      title: '专属SVIP客服',
      desc: '优先响应 1对1解疑'
    },
    {
      imgClass: 'i_5',
      title: '多电脑同时在线',
      desc: '一个账号全家共享'
    },
  ]

  /** 权益卡片表述列表 */
  interestCards = [
    {
      label: '电脑效率',
      arr: [
        {
          img: require('./_images/computer_1.png'),
          title: '弹窗拦截',
          desc: '自动拦截广告弹窗，净化电脑环境不受打扰'
        },
        {
          img: require('./_images/computer_2.png'),
          title: '软件卸载',
          desc: '流氓软件卸载，防止反复安装'
        },
        {
          img: require('./_images/computer_3.png'),
          title: '隐私清理',
          desc: '清理上网隐私痕迹，保护个人信息安全'
        },
        {
          img: require('./_images/computer_4.png'),
          title: '系统盘瘦身',
          desc: '转移虚拟内存，大文件搬家，释放C盘空间'
        },
        {
          img: require('./_images/computer_5.png'),
          title: '碎片清理',
          desc: '清理磁盘冗余碎片，提高电脑使用寿命'
        },
      ]
    },
    {
      label: '文档处理',
      arr: [
        {
          img: require('./_images/file_1.png'),
          title: 'PDF转换',
          desc: 'Word、Excel、PPT、CAD、TXT丰富格式互转'
        },
        {
          img: require('./_images/file_2.png'),
          title: 'PDF编辑',
          desc: '支持文本编辑、图片编辑和页面编辑'
        },
        {
          img: require('./_images/file_3.png'),
          title: 'PDF阅读',
          desc: '多种阅读模式、阅读背景切换，更优的阅读体验'
        },
      ]
    },
    {
      label: '图片处理',
      arr: [
        {
          img: require('./_images/photo_1.png'),
          title: '快速看图',
          desc: '大图秒开，格式全，还原真实色彩'
        },
        {
          img: require('./_images/photo_2.png'),
          title: '图片美化',
          desc: '支持图片裁剪、旋转、涂抹、色彩调整等'
        },
        {
          img: require('./_images/photo_3.png'),
          title: '图片转换识别',
          desc: '支持图片转PDF、OCR文字识别'
        },
      ]
    },
    {
      label: '视频转换',
      arr: [
        {
          img: require('./_images/video_1.png'),
          title: '音视频转换',
          desc: '提供MP3、MP4、4K等输出格式'
        },
        {
          img: require('./_images/video_2.png'),
          title: '视频去水印',
          desc: '一键提取无水印视频'
        },
        {
          img: require('./_images/video_3.png'),
          title: '视频压缩',
          desc: '无损压缩，支持多视频批量压缩'
        },
      ]
    },
    {
      label: '精品模板',
      arr: [
        {
          img: require('./_images/word_1.png'),
          title: 'Word模板',
          desc: '求职简历、求职攻略、活动策划模板'
        },
        {
          img: require('./_images/word_2.png'),
          title: 'PPT模板',
          desc: '论文答辩、学习汇报，还有更多丰富模板'
        },
        {
          img: require('./_images/word_3.png'),
          title: 'Excel模板',
          desc: '课程表，学习管理、计划模板'
        },
      ]
    },
  ]

  constructor (
    private http: Http,
    private reporter: ReportService
  ) {
  }

  report = this.reporter.webReport({
    nshow: 2,
    nfrom: 1,
    click: 0
  }, this.reporter.table.infockeniu_activity_page)

  /**
   * @description 请求活动页配置
   */
  requestActivityPageConfig (params: ActivityParams) {
    return this.http.post('/activity/config', params)
  }
}


































import { defineComponent, reactive, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    time: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  setup (props, context) {
    const viewModel = reactive({
      time: props.time
    })

    watch(() => props.time, newVal => {
      viewModel.time = newVal
    })

    /**
     * @description 关闭
     */
    function sceClose () {
      context.emit('close')
    }

    return {
      viewModel,
      sceClose
    }
  }
})
